import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Typography, Button } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section, CardPricingStandard } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  topContainer: {
    backgroundColor: 'rgba(255, 100, 83, 1)',
  },
  sectionContainer: {
    borderRadius: '0 0 100% 0',
    backgroundColor: 'rgb(25,25,25)',
    paddingBottom: theme.spacing(3),
  },
  textWhite: {
    color: 'white',
  },
  textBlack: {
    color: 'rgb(35, 35, 35)',
    fontWeight: 800,
    fontSize: '15px',
    marginTop: '0px'
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 2)
  },
  toggleButtonGroup: {
    background: 'transparent',
  },
  toggleButton: {
    background: 'transparent',
    border: '1px solid white',
    padding: theme.spacing(1, 5),
  },
  toggleButtonActive: {
    backgroundColor: 'white !important',
  },
  toggleTitle: {
    textTransform: 'capitalize',
  },
  toggleTitleActive: {
    color: 'rgb(25,25,25)',
  },
  pricingContainer: {
    borderRadius: '100% 0 0 0',
    backgroundColor: 'rgb(25,25,25)',
    position: 'relative',
    // marginTop: theme.spacing(-20),
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  cardPricing: {
    '& .countup-number__count-wrapper': {
      textAlign: 'left',
      marginBottom: 0,
      fontWeight: 'bold',
    },
  },
}));

const Main = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  const [pricingOption, setPricingOption] = React.useState('monthly');

  const handleClick = (event, newPricingOption) => {
    setPricingOption(newPricingOption);
  };

  return (
    <div className={classes.topContainer} {...rest}>
      <div className={classes.topOverlap}>
        <div className={classes.sectionContainer}>
          <Section className={classes.pagePaddingTop}>
            <>
              <SectionHeader
                title={<span><span style={{ color: 'rgb(255,100,83)' }}>Simple</span> pricing based on your <span style={{ color: 'rgb(255,100,83)' }}>needs</span></span>}
                subtitle={<span>Free up to <span style={{ color: 'rgb(255,100,83)' }}>100</span> items</span>}
                titleProps={{
                  className: clsx(classes.textWhite, classes.fontWeightBold),
                  variant: 'h3',
                }}
                subtitleProps={{
                  color: 'textPrimary'
                }}
                data-aos="fade-up"
              />
              <div className={classes.toggleContainer} data-aos="fade-up">
                <ToggleButtonGroup
                  value={pricingOption}
                  exclusive
                  onChange={handleClick}
                  className={classes.toggleButtonGroup}
                >
                  <ToggleButton
                    value="monthly"
                    className={clsx(
                      classes.toggleButton,
                      pricingOption === 'monthly' ? classes.toggleButtonActive : {},
                    )}
                  >
                    <Typography
                      variant="subtitle1"
                      className={clsx(
                        classes.fontWeightBold,
                        classes.textWhite,
                        classes.toggleTitle,
                        pricingOption === 'monthly'
                          ? classes.toggleTitleActive
                          : {},
                      )}
                    >
                      One month
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    value="annual"
                    className={clsx(
                      classes.toggleButton,
                      pricingOption === 'annual' ? classes.toggleButtonActive : {},
                    )}
                  >
                    <Typography
                      variant="subtitle1"
                      className={clsx(
                        classes.fontWeightBold,
                        classes.textWhite,
                        classes.toggleTitle,
                        pricingOption === 'annual' ? classes.toggleTitleActive : {},
                      )}
                    >
                      One year
                    </Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </>
          </Section>
        </div>
        <div className={classes.pricingContainer}>
          <div>
            <Section className={classes.sectionNoPaddingTop}>
              <Grid container spacing={isMd ? 4 : 2}>
                {data.map((item, index) => (
                  <Grid item xs={12} md={6} data-aos="fade-up" key={index}>
                    <CardPricingStandard
                      variant="outlined"
                      withShadow={item.isHighlighted ? true : false}
                      title={item.title}
                      liftUp
                      subtitle={item.subtitle}
                      priceComponent={
                        <div>
                          <Typography
                            variant="h3"
                            component="span"
                          >
                            €{' '}
                          </Typography>
                          <Typography
                            variant="h3"
                            component="span"
                            color="secondary"
                          >
                            {pricingOption === 'annual'
                              ? item.annual
                              : item.monthly}
                          </Typography>
                          <Typography
                            variant="body1"
                            component="span"
                          >
                            {`  (VAT included)`}
                          </Typography>
                          {/* {pricingOption === 'annual'
                            ? (
                              < Typography component="span" variant="subtitle1">
                                {item.priceSuffix}
                              </Typography>
                            )
                          : null} */}
                        </div>
                      }
                      features={item.features}
                      featureCheckComponent={
                        <Icon
                          fontIconClass="fas fa-check"
                          fontIconColor={'white'}
                        />
                      }
                      cta={
                        <Button
                          color="secondary"
                          variant={item.isHighlighted ? 'contained' : 'outlined'}
                          fullWidth
                          size="large"
                          href={`https://inventory-backoffice.qeerio.com/application/subscriptions?planSize=${item.title === 'Standard inventory' ? 'medium' : 'big'}&planPeriod=${pricingOption === "annual" ? "year" : "month"}`}
                        >
                          Start now
                        </Button>
                      }
                      disclaimer={item.disclaimer}
                      className={classes.cardPricing}
                    />
                  </Grid>
                ))}
              </Grid>
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
};

Main.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Main;
