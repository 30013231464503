import React from 'react';

export const pricings = [
  {
    title: 'Standard inventory',
    subtitle: 'If you need a complete and simple inventory withtout the event features!',
    monthly: 79,
    annual: 788,
    priceSuffix: ' per month',
    features: [
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> items in inventory</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>20</b></span> item booking per events</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> team members</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> units</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> custom fields</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Full</b></span> access to all features</span>
    ],
    isHighlighted: true
  },
  {
    title: 'Event inventory',
    subtitle: 'Well-suited for event companies!',
    monthly: 108,
    annual: 1088,
    priceSuffix: ' per month',
    features: [
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> items in inventory</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> item booking per events</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> team members</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> units</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> custom fields</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Full</b></span> access to all features</span>
    ],
    isHighlighted: false
  }
];

export const faq = [
  {
    id: 'faq-1',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-2',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-3',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-4',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-5',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-6',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
];

export const plans = [
  {
    title: 'Move deals through your funnel – fast',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
  {
    title: 'On demand services right at your front-door',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
  {
    title: 'Online 24/7 support',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
];
